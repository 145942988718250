import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import useAppContext from "../../../providers/App";

import Newsletter from "../../molecule/Newsletter";
import ContactInfoBlock from "../../molecule/ContactInfoBlock";
import LineSeparator from "../../atom/LineSeparator";

import styles from "./Footer.module.scss";

function Footer() {
  const location = useLocation();
  const { setIsLegalInformationOpen } = useAppContext();

  return (
    <footer className={styles.footer}>
      {/* <Newsletter className={styles.newsletter} /> */}
      <div className={classNames("container", styles.container)}>
        <ContactInfoBlock
          className={styles.block}
          icon={faEnvelope}
          title=""
          subtitle="CLIQUE AQUI E ENTRE EM CONTATO"
          description="Não encontrou o que procurava? Sem problemas! Envie-nos um e-mail que entraremos em contato com você."
          onClick={() => {
            window.location.href = "https://www.stihl.com.br/contato.aspx";
          }}
        />
        <ContactInfoBlock
          className={styles.block}
          icon={faPhone}
          title="SUPORTE TELEFÔNICO"
          subtitle="0800 707 5001"
          description="Prefere falar com um atendente? O atendimento neste canal está disponível de segunda a sexta-feira, das 08h às 19h (exceto feriados)."
        />
        <LineSeparator className={styles.line} />
        <div className={styles.smallText}>
          {location.pathname !== "/promocao" && (
            <p>
              <strong>
                *Válido para todos os modelos exceto Lavadoras de alta pressão
                RE 232.
              </strong>{" "}
              Saiba mais em:
              <a
                className={styles.link}
                href="https://www.stihl.com.br/garantia.aspx"
                target="_blank"
              >
                https://www.stihl.com.br/garantia.aspx
              </a>{" "}
            </p>
          )}
          <p>
            Este site é regulado pela Informação Legal, além da legislação
            vigente. É dever de todos os usuários a devida leitura da informação
            legal disponibilizada neste site, em links próprios. Ao utilizar
            este site, todas as condições da{" "}
            <span
              className={styles.link}
              onClick={() => setIsLegalInformationOpen(true)}
            >
              Informação legal
            </span>{" "}
            e da{" "}
            <a
              className={styles.link}
              href="http://crm.stihl.com.br/privacidade/STIHL_Pol%C3%ADtica%20de%20Privacidade%20Geral%2007092020.pdf?_ga=2.159493493.91224378.1603117764-147512325.1593118451;"
              target="_blank"
            >
              Política de Privacidade
            </a>{" "}
            da STIHL são aplicáveis aos usuários.
          </p>
          <p>
            Pertencem à STIHL Ferramentas Motorizadas Ltda. todos os direitos
            sobre o domínio e sobre o conteúdo publicado nesta Página.
          </p>
          <p>
            Dúvidas, reclamações ou sugestões podem ser direcionadas para 0800
            707 5001 ou por escrito pelo link{" "}
            <a
              className={styles.link}
              href="https://www.stihl.com.br/fale-conosco-por-e-mail.aspx"
              target="_blank"
            >
              "Fale Conosco por e-mail"
            </a>
            .
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
